function omit(key, obj) {
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

export default class ApiDataProcessor {

  // this.data = omit('timestamp', data)

    constructor(data) {

      if (!data || data == undefined) {
        this.data = {}
      } else {

        for (let i = 0; i < data.length; i++) {
          if (Array.isArray(data[i])) {
            data[i] = {'binance-futures': data[i]}
          }
        }


        const newData = omit('timestamp', data)
        const newNewData = {}

        for (let i = 0; i < Object.keys(newData).length; i++) {
          const keysOfNewNewData = Object.keys(newData[Object.keys(newData)[i]])
          for (let y = 0; y < keysOfNewNewData.length; y++) {
            if (keysOfNewNewData[y] !== 'all') {
              newNewData[keysOfNewNewData[y]] = newData[i][keysOfNewNewData[y]]
            }
          }
        }
        this.data = newNewData
      }
    }

    // TEMP FUTURES ADDITION
    get totalFuturesBalance() {
      let total = 0
      if (this.data['binance-futures']) {
        const positions = this.data['binance-futures']
  
        for (let i = 0; i < positions.length; i++) {
          total = total + parseFloat(positions[i].initialMargin) + parseFloat(positions[i].unrealizedProfit)
        }
      }
      return total
    }
    get totalFuturesPnl() {
      let total = 0
      if (this.data['binance-futures']) {
        const positions = this.data['binance-futures']
  
        for (let i = 0; i < positions.length; i++) {
          total = total + parseFloat(positions[i].unrealizedProfit)
        }
      }
      return total
    }

    get futuresPositions() {
      return this.data['binance-futures']
    }
    
    // get totalFuturesBalance() {
    //   if (this.data['binance-futures']) {
    //     const positions = this.data['binance-futures']
    //     let total = 0
  
    //     for (let i = 0; i < positions.length; i++) {
    //       total = total + positions[i].initialMargin + positions[i].unrealizedProfit
    //     }
    //   }
    // }
    // END TEMP FUTURES ADDITION

    /////////////////////////////////////////////////////
    // Key retrieval for ordered processing of objects //
    /////////////////////////////////////////////////////
    get keys() {
      return Object.keys(this.data)
    }

    get defiKeys() {
      return Object.keys(this.defiAccounts)
    }

    // subset of defi keys
    get liquidDefiKeys() {
      return Object.keys(this.liquidDefiAccounts)
    }

    // subset of defi keys
    get stakedKeys() {
      return Object.keys(this.stakedAccounts)
    }

    get cefiKeys() {
      return Object.keys(this.cefiAccounts)
    }

    get liquidKeys() {
      this.cefiKeys.concat(this.liquidDefiKeys)
    }

    ////////////////////////////////////////////////////////////////////////
    // Account name retrieval for UI display (e.g. 0x12345, FTX, Binance) //
    ////////////////////////////////////////////////////////////////////////
    get defiAccountNames() {
      return [...new Set(Object.keys(this.defiAccounts).map(addr => addr.split('-')[addr.split('-').length - 1]))]
    }

    get cefiAccountNames() {
      return Object.keys(this.cefiAccounts)
    }

    get stringifiedPositionToAccountMap() {
      const map = {}
      const tempData = JSON.parse(JSON.stringify(this.data))
      for (let [key, value] of Object.entries(tempData)) {
        if ((key) == 'TOTAL') {
          continue
        } else {
          if (key.includes('staked')) {
            key = key.split('-')[2]
          } else if (key.includes('-')) {
            key = key.split('-')[1]
          }
          for (const position of value) {
            if (position[0] == 'TOTAL') {
              {}
            } else {
              map[JSON.stringify(position)] = key
            }
          }
        }
      }
      return map
    }

    get stringifiedPositionToChainMap() {
      const map = {}
      const tempData = JSON.parse(JSON.stringify(this.data))
      for (let [key, value] of Object.entries(tempData)) {
        let chain = 'None'
        if ((key) == 'TOTAL') {
          continue
        } else {
          if (key.includes('staked')) {
            chain = key.split('-')[0] + ' (' + key.split('-')[1] + ')'
          } else if (key.includes('-')) {
            chain = key.split('-')[0]
          } else {
            chain = '---'
          }
          for (const position of value) {
            if (position[0] == 'TOTAL') {
              {}
            } else {
              if (chain.split(' ').length > 1) {
                const split = chain.split(' ')
                chain = split[0].toUpperCase() + ' ' + '(' + split[1].charAt(1).toUpperCase() + split[1].slice(2)
              } else {
                chain = chain.toUpperCase()
              }
              map[JSON.stringify(position)] = chain
            }
          }
        }
      }
      return map
    }

    //////////////////////////////////////////////////////////////////
    // Account & position retrieval for each defi & cefi separately //
    //////////////////////////////////////////////////////////////////
    get defiAccounts() {
      return this.keys.filter(
        key => (key.includes('-') && (!key.includes('futures')))
      )
      .reduce((cur, key) => { return Object.assign(cur, { [key]: this.data[key] })}, {})
    }

    get liquidDefiAccounts() {
      return this.keys.filter(
        key => (key.includes('-') && (!key.includes('staked')) && (!key.includes('futures')))
      )
      .reduce((cur, key) => { return Object.assign(cur, { [key]: this.data[key] })}, {})
    }

    get stakedAccounts() {
      return this.keys.filter(
        key => (key.includes('-') && (key.includes('staked')))
      )
      .reduce((cur, key) => { return Object.assign(cur, { [key]: this.data[key] })}, {})
    }

    get cefiAccounts() {
      return this.keys.filter(
        key => ((key.includes('-') === false) && (key.includes('all') === false))
      )
      .reduce((cur, key) => { return Object.assign(cur, { [key]: this.data[key] })}, {})
    }

    getCefiAccounts(exchange) {
      return this.keys.filter(
        key => ((key.includes('-') === false) && (key.includes('all') === false) && (key.includes(exchange)))
      )
      .reduce((cur, key) => { return Object.assign(cur, { [key]: this.data[key] })}, {})
    }

    get allLiquidAccounts() {
      return {...this.liquidDefiAccounts, ...this.cefiAccounts}
    }

    get allAccounts() {
      return this.data
    }

    getSetOfAccounts(type) {
      switch(type) {
        case 'defi':
          return this.defiAccounts
          break
        case 'liquidDefi':
          return this.liquidDefiAccounts
          break
        case 'staked':
          return this.stakedAccounts
          break
        case 'cefi':
          return this.cefiAccounts
          break
        case 'binance':
          return this.getCefiAccounts('binance')
          break
        case 'ftx':
          return this.getCefiAccounts('ftx')
          break
        case 'kraken':
          return this.getCefiAccounts('kraken')
          break
        case 'liquid':
          return this.allLiquidAccounts
          break
        default:
          return this.allAccounts
          break
      }
    }

    getPositionsFromSetOfAccounts(type) {
      const accounts = this.getSetOfAccounts(type)
      const keys = Object.keys(accounts)

      let positions = []
      for (let i = 0; i < keys.length; i++) {
        positions.push(this.data[keys[i]])
      }
      positions = positions.flat().filter(item => item[0] != 'TOTAL')
      return positions
    }

    getTotalBalanceFromSetOfAccounts(type) {
      const positions = this.getPositionsFromSetOfAccounts(type)

      let total = 0
      for (let i = 0; i < positions.length; i++) {
        if (positions && positions[i] && positions[i][1]) total += positions[i][1].USD
      }
      return total
    }

    /////////////////////////////////////
    // Specific address data retrieval //
    /////////////////////////////////////
    getTotalBalanceOfCefiAccountInUsd(name) {
      return this.data[name] ? this.data[name][0][1].USD : 0
    }

    getTotalBalanceOfDefiAccountInUsd(address) {
      return this.getAddressLiquidBalanceInUsd(address) + this.getAddressStakedBalanceInUsd(address)
    }

    getAddressLiquidPositions(address) {
      try {
        const addressLiquid = []
        for (let i = 0; i < this.keys.length; i++) {
          if (this.keys[i].includes('-' + address) && !this.keys[i].includes('staked')) {
            addressLiquid.push(this.data[this.keys[i]])
          }
        }
        return addressLiquid
      } catch (e) {
        console.log(e)
      }
    }
    getAddressLiquidBalanceInUsd(address) {
      try {
        const pos = this.getAddressLiquidPositions(address)
        let bal = 0

        for (let i = 0; i < pos.length; i++) {
          bal += pos[i][0][1].USD
        }

        return bal
      } catch (e) {
        console.log(e)
      }
    }
  
    getAddressLiquidPositionsOnChain(address, chain) {
      try {
          return this.data[chain + '-' + address]
      } catch (e) {
        console.log(e)
      }
    }
  
    getAddressStakedPositions(address) {
      try {
        const addressStaked = []
        for (let i = 0; i < this.keys.length; i++) {
          if (this.keys[i].includes('-' + address) && this.keys[i].includes('staked')) {
            addressStaked.push(this.data[this.keys[i]])
          }
        }
        return addressStaked
      } catch (e) {
        console.log(e)
      }
    }
  
    getAddressStakedBalanceInUsd(address) {
      try {
        const pos = this.getAddressStakedPositions(address)
        let bal = 0

        for (let i = 0; i < pos.length; i++) {
          bal += pos[i][0][1].USD
        }

        return bal
      } catch (e) {
        console.log(e)
      }
    }
  
    getAddressStakedPositionsOnChain(address, chain) {
      try {
          return this.data[chain + '-staked-' + address]
      } catch (e) {
        console.log(e)
      }
    }

  
    getStakedPositionsOnAChain(chain) {
      try {
        const staked = []
        for (let i = 0; i < this.keys.length; i++) {
          if (this.keys[i].includes(chain + '-') && this.keys[i].includes('staked')) {
            staked.push(this.data[this.keys[i]])
          }
        }
        return staked
      } catch (e) {
        console.log(e)
      }
    }

  
    getLiquidPositionsOnAChain(chain) {
      try {
        const liquid = []
        for (let i = 0; i < this.keys.length; i++) {
          if (this.keys[i].includes(chain + '-') && !this.keys[i].includes('staked')) {
            liquid.push(this.data[this.keys[i]])
          }
        }
        return liquid
      } catch (e) {
        console.log(e)
      }
    }

  
    getStakedBalanceOnAChain(chain) {
      try {
        const positions = this.getStakedPositionsOnAChain(chain)
        let total = 0
        for (let i = 0; i < positions.length; i++) {
          total += positions[i][0][1].USD
        }

        return total

      } catch (e) {
        console.log(e)
      }
    }

  
    getLiquidBalanceOnAChain(chain) {
      try {
        const positions = this.getLiquidPositionsOnAChain(chain)
        let total = 0
        for (let i = 0; i < positions.length; i++) {
          total += positions[i][0][1].USD
        }

        return total

      } catch (e) {
        console.log(e)
      }
    }

  
    getBalanceOnAChain(chain) {
      return this.getLiquidBalanceOnAChain(chain) + this.getStakedBalanceOnAChain(chain)
    }

  }
  
//   export function getPositionsOnAllChains(data, address){
//       try {
//           return {
//               eth: getPositionsOnAChain(data, address, 'eth'),
//               'eth-staked': getStakedPositionsOnAChain(data, address, 'eth'),
//               ftm: getPositionsOnAChain(data, address, 'ftm'),
//               'ftm-staked': getStakedPositionsOnAChain(data, address, 'ftm'),
//               bsc: getPositionsOnAChain(data, address, 'bsc'),
//               'bsc-staked': getStakedPositionsOnAChain(data, address, 'bsc'),
//               matic: getPositionsOnAChain(data, address, 'matic'),
//               'matic-staked': getStakedPositionsOnAChain(data, address, 'matic')
//           }
//       } catch (e) {
  
//       }
//   }
  
//   export function getChartsData(data, addresses){
//       function getPercent(chainTotal, total) {
//           return ((chainTotal / total) * 100).toFixed(2)
//       }
//       try {
  
//           let ethLiquidTotal = 0
//           let bscLiquidTotal = 0
//           let ftmLiquidTotal = 0
//           let maticLiquidTotal = 0
  
//           let ethStakedTotal = 0
//           let bscStakedTotal = 0
//           let ftmStakedTotal = 0
//           let maticStakedTotal = 0
  
//           for (let i = 0; i < addresses.length; i++) {
  
//               ethLiquidTotal += data['eth-' + addresses[i]][0][1].USD
//               bscLiquidTotal += data['bsc-' + addresses[i]][0][1].USD
//               ftmLiquidTotal += data['ftm-' + addresses[i]][0][1].USD
//               maticLiquidTotal += data['matic-' + addresses[i]][0][1].USD
  
//               ethStakedTotal += data['eth-staked-' + addresses[i]][0][1].USD
//               bscStakedTotal += data['bsc-staked-' + addresses[i]][0][1].USD
//               ftmStakedTotal += data['ftm-staked-' + addresses[i]][0][1].USD
//               maticStakedTotal += data['matic-staked-' + addresses[i]][0][1].USD
  
//           }
  
//           const ethTotal = ethLiquidTotal + ethStakedTotal
//           const bscTotal = bscLiquidTotal + bscStakedTotal
//           const ftmTotal = ftmLiquidTotal + ftmStakedTotal
//           const maticTotal = maticLiquidTotal + maticStakedTotal
  
//           const liquidTotal = ethLiquidTotal + bscLiquidTotal + ftmLiquidTotal + maticLiquidTotal
//           const stakedTotal = ethStakedTotal + bscStakedTotal + ftmStakedTotal + maticStakedTotal
      
//           const total = liquidTotal + stakedTotal
  
//           return {
//               accountSplit: {
//                   eth: getPercent(ethTotal, total),
//                   bsc: getPercent(bscTotal, total),
//                   ftm: getPercent(ftmTotal, total),
//                   matic: getPercent(maticTotal, total)
//               },
//               liquidSplit: {
//                   liquid: getPercent(liquidTotal, total),
//                   staked: getPercent(stakedTotal, total),
//               },
//               liquidBalance: {
//                   liquid: liquidTotal,
//                   staked: stakedTotal,
//               },
//               chainBalances: {
//                   eth: ethLiquidTotal,
//                   bsc: bscLiquidTotal,
//                   ftm: ftmLiquidTotal,
//                   matic: maticLiquidTotal,
//                   'eth-staked': ethStakedTotal,
//                   'bsc-staked': bscStakedTotal,
//                   'ftm-staked': ftmStakedTotal,
//                   'matic-staked': maticStakedTotal,
//               }
//           }
  
//       } catch (e) {
  
//       }
//   }
