export default {
    button: {
        primary: {
        base: 'text-white bg-blue-600 border border-transparent',
        active: 'active:bg-blue-600 hover:bg-blue-700 focus:ring focus:ring-blue-300',
        },
        size: {
          larger: 'px-10 py-4 rounded-sm',
          large: 'px-5 py-3 rounded-sm',
          regular: 'px-4 py-2 rounded-sm text-sm',
          small: 'px-3 py-1 rounded-sm text-sm',
        }
    },
  
    card:{
        base: 'min-w-0 rounded-sm ring-1 ring-black ring-opacity-5 overflow-hidden', 
    },

    tableContainer: {
      base: 'w-full overflow-hidden rounded-sm ring-1 ring-black ring-opacity-5',
    }
  }