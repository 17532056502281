const firebaseConfig = {
    apiKey: "AIzaSyASZ5fFi0DT_-Wwnvuo87DuX2dBkjmPHLU",
    authDomain: "qfg-hedgefund-dashboard.firebaseapp.com",
    databaseURL: "https://qfg-hedgefund-dashboard-default-rtdb.firebaseio.com",
    projectId: "qfg-hedgefund-dashboard",
    storageBucket: "qfg-hedgefund-dashboard.appspot.com",
    messagingSenderId: "80175340773",
    appId: "1:80175340773:web:3845a3139d4c291fff480c"
  };

export default firebaseConfig