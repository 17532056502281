import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get, serverTimestamp, update } from "firebase/database";

import ApiDataProcessor from "./utils/ApiDataProcessor";
import { convertTimestampToDate, getLiveData } from "./utils/utils";
import dummy from "./utils/dummy.json";

import web3 from "web3";

import firebaseConfig from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const Pacman = lazy(() => import("./components/Pacman"));

function App() {
  const [progress, setProgress] = useState(0);
  const [numAccounts, setNumAccounts] = useState(0);

  const [clientsLoaded, setClientsLoaded] = useState(false);
  const [snapshotsLoaded, setSnapshotsLoaded] = useState(false);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [snapshots, setSnapshots] = useState([]);

  const [usingSnapshot, setUsingSnapshot] = useState(false);
  const [snapshotTimestring, setSnapshotTimestring] = useState("");

  const [snapshotData, setSnapshotData] = useState(null);
  const [snapshotDp, setSnapshotDp] = useState(null);

  const [nonLiveSnapDps, setNonLiveSnapDps] = useState(null);
  const [nonLiveSnapTimestrings, setNonLiveSnapTimestrings] = useState("");

  const [data, setData] = useState(null);
  const [dp, setDp] = useState(null);

  const [cefiAccounts, setCefiAccounts] = useState([]);
  const [defiAccounts, setDefiAccounts] = useState([]);

  const [clients, setClients] = useState([]);

  const [searchedValue, setSearchedValue] = useState("");

  const getSnapshots = () => {
    const snapshotRef = ref(db, "snapshots/");

    get(snapshotRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const snaps = snapshot.val();
          setSnapshots(snaps);

          const tempNonLiveSnapDps = [];
          const tempNonLiveSnapTimestrings = [];
          for (let i = 0; i < Object.keys(snaps).length; i++) {
            tempNonLiveSnapDps.push(new ApiDataProcessor(snaps[Object.keys(snaps)[i]].snapshot));
            tempNonLiveSnapTimestrings.push(convertTimestampToDate(snaps[Object.keys(snaps)[i]].timestamp)[0] + "-" + convertTimestampToDate(snaps[Object.keys(snaps)[i]].timestamp)[1]);
          }

          setNonLiveSnapDps(tempNonLiveSnapDps);
          setNonLiveSnapTimestrings(tempNonLiveSnapTimestrings);
        } else {
          setSnapshots([]);
        }
        setSnapshotsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setSnapshotsLoaded(false);
        getSnapshots();
      });
  };

  const getAccounts = () => {
    const accountsRef = ref(db, "accounts/");

    get(accountsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          try {
            if (value && value.cefi) setCefiAccounts(Object.keys(value.cefi).map((key) => value.cefi[key]));
          } catch (e) {
            console.log(e);
          }
          try {
            if (value && value.defi) setDefiAccounts(Object.keys(value.defi).map((key) => value.defi[key]));
          } catch (e) {
            console.log(e);
          }
          setAccountsLoaded(true);
        }
        setAccountsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setAccountsLoaded(false);
        getAccounts();
      });
  };

  const getClients = () => {
    const clientsRef = ref(db, "clients/");

    get(clientsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          try {
            if (value) setClients(Object.keys(value).map((key) => value[key]));
          } catch (e) {
            console.log(e);
          }
          setClientsLoaded(true);
        }
        setClientsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setClientsLoaded(false);
        getClients();
      });
  };

  const updateProgress = (responses) => {
    setProgress(responses);
    return;
  };

  const getData = async () => {
    // const d = await getLiveData(updateProgress, setNumAccounts)
    // setData(d)
    setDataLoaded(true);
  };

  const writeSnapshot = (snapshot, note) => {
    if (!note) {
      alert("Please add a note");
      return;
    }
    if (!snapshot) return;

    console.log("writing snapshot");
    setSnapshotsLoaded(false);
    push(ref(db, "snapshots/"), {
      snapshot,
      note,
      timestamp: serverTimestamp(),
    }).then(() => {
      getSnapshots();
    });
  };

  const setDataToSnapshot = (snapshot, timestring) => {
    setSnapshotTimestring(timestring);
    setUsingSnapshot(true);
    setSnapshotData(snapshot);
    setSnapshotDp(new ApiDataProcessor(snapshotData));
  };

  const goLive = () => {
    setUsingSnapshot(false);
  };

  const writeCefiAccount = (exchange, nickname, description, apiKey, secretKey) => {
    if (!exchange || !nickname || !description || !apiKey || !secretKey) {
      alert("Please fill in every field");
      return;
    }

    console.log("writing cefi");
    setAccountsLoaded(false);
    set(ref(db, "accounts/cefi/" + nickname), {
      nickname,
      exchange,
      description,
      apiKey,
      secretKey,
      timestamp: serverTimestamp(),
    }).then(() => {
      getAccounts();
    });
  };

  const writeDefiAccount = (nickname, description, address) => {
    if (!web3.utils.isAddress(address)) {
      alert("Invalid address");
      return;
    }

    if (!nickname || !description || !address) {
      alert("Please fill in every field");
      return;
    }

    console.log("writing defi");
    setAccountsLoaded(false);
    set(ref(db, "accounts/defi/" + nickname), {
      nickname,
      description,
      address,
      timestamp: serverTimestamp(),
    }).then(() => {
      getAccounts();
    });
  };

  const delDefiAccounts = (ids) => {
    console.log("deleting defi");

    const toDelete = {};
    for (let i = 0; i < ids.length; i++) {
      toDelete[ids[i]] = null; // setting value to null deletes the key
    }

    //deletion
    update(ref(db, "accounts/defi/"), toDelete);

    const defiRef = ref(db, "accounts/defi/");

    get(defiRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          try {
            // setCefiAccounts(Object.keys(value).map(key => value[key]))
            setDefiAccounts(Object.keys(value).map((key) => value[key]));
          } catch (e) {
            console.log(e);
          }
        } else {
          setDefiAccounts([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const delCefiAccounts = (ids) => {
    console.log("deleting cefi");

    const toDelete = {};
    for (let i = 0; i < ids.length; i++) {
      toDelete[ids[i]] = null; // setting value to null deletes the key
    }

    //deletion
    update(ref(db, "accounts/cefi/"), toDelete);

    const cefiRef = ref(db, "accounts/cefi/");

    get(cefiRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          try {
            // setCefiAccounts(Object.keys(value).map(key => value[key]))
            setCefiAccounts(Object.keys(value).map((key) => value[key]));
          } catch (e) {
            console.log(e);
          }
        } else {
          setCefiAccounts([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const writeClient = (name, lastName, deposit, email, number, location, description) => {
    if (!name || !lastName || !deposit || !email || !number || !location || !description) {
      alert("Please fill in every field");
      return;
    }
    console.log("writing client");
    setClientsLoaded(false);
    set(ref(db, "clients/" + name + " " + lastName), {
      name,
      lastName,
      deposit,
      email,
      number,
      location,
      description,
      timestamp: serverTimestamp(),
    }).then(() => {
      getClients();
    });
  };

  const delClients = (names) => {
    console.log("deleting client");

    const toDelete = {};
    for (let i = 0; i < names.length; i++) {
      toDelete[names[i]] = null; // setting value to null deletes the key
    }

    //deletion
    update(ref(db, "clients/"), toDelete);

    const clientsRef = ref(db, "clients/");

    get(clientsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          try {
            setClients(Object.keys(value).map((key) => value[key]));
          } catch (e) {
            console.log(e);
          }
        } else {
          setClients([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const delSnapshot = id => {
  //   console.log('deleting snap')
  //   const snapshotRef = ref(db, 'snapshots/')

  //   //deletion
  //   set(ref(db, 'snapshots/' + id), null);

  //   get(snapshotRef).then((snapshot) => {
  //     if (snapshot.exists()) {
  //       const value = snapshot.val()
  //       try {
  //         setSnapshots(value)
  //       } catch(e) {
  //         console.log(e)
  //       }
  //     } else {
  //       setSnapshots([])
  //     }
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // }

  useEffect(() => {
    setDp(new ApiDataProcessor(data));
  }, [data]);

  useEffect(() => {
    setSnapshotDp(new ApiDataProcessor(snapshotData));
  }, [snapshotData]);

  useEffect(() => {
    setDataLoaded(false);
    getData();
  }, [cefiAccounts, defiAccounts]);

  useEffect(() => {
    getSnapshots();
    getAccounts();
    getClients();
  }, []);

  // console.log('s' + snapshotsLoaded)
  // console.log('a' + accountsLoaded)
  // console.log('d' + dataLoaded)

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />

          <Redirect exact from="/" to="/login" />

          <Route
            path="/"
            render={() => (
              <Layout
                snapshots={snapshots}
                data={usingSnapshot ? snapshotData : data}
                dp={usingSnapshot ? snapshotDp : dp}
                writeCefiAccount={writeCefiAccount}
                writeDefiAccount={writeDefiAccount}
                delCefiAccounts={delCefiAccounts}
                delDefiAccounts={delDefiAccounts}
                cefiAccounts={cefiAccounts}
                defiAccounts={defiAccounts}
                writeSnapshot={writeSnapshot}
                setDataToSnapshot={setDataToSnapshot}
                usingSnapshot={usingSnapshot}
                snapshotTimestring={snapshotTimestring}
                nonLiveSnapDps={nonLiveSnapDps}
                nonLiveSnapTimestrings={nonLiveSnapTimestrings}
                goLive={goLive}
                clients={clients}
                delClients={delClients}
                writeClient={writeClient}
                searchedValue={searchedValue}
                setSearchedValue={setSearchedValue}
                progress={progress}
                numAccounts={numAccounts}
                everythingLoaded={snapshotsLoaded && dataLoaded && accountsLoaded && clientsLoaded}
                // everythingLoaded={true}
              />
            )}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
