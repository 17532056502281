import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, child, serverTimestamp, onValue, remove } from "firebase/database";

import axios from 'axios'

import moment from 'moment'

import firebaseConfig from '../firebaseConfig.js'

export function convertTimestampToDate(ts) {
    const d = new Date(ts)

    return [moment(d).format('DD/MM/YY'), moment(d).format('HH:mm')]
}

export function numberWithCommas(x) {
    if (!x) return
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithCommasAndDollarSign(x) {
    if (!x) return
    return '$ ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function sortPositionsDescending(array) {
    return array.sort((a,b) => b[1].USD-a[1].USD)
}

export function getChainList() {
    return ['ETH', 'BSC', 'FTM', 'MATIC']
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async function demo() {
    console.log('Taking a break...');
    await sleep(2000);
    console.log('Two seconds later, showing sleep in a loop...');
  
    // Sleep in loop
    for (let i = 0; i < 5; i++) {
      if (i === 3)
        await sleep(2000);
      console.log(i);
    }
  }

export async function getLiveData(updateProgress, setNumAccounts) {
    const app = initializeApp(firebaseConfig)
    const db = getDatabase(app)

    const accountsRef = ref(db, 'accounts/')

    const snapshot = await get(accountsRef)

    if (snapshot.exists()) {
        const value = snapshot.val()
        const cefiKeys = value.cefi ? Object.keys(value.cefi) : []
        const defiKeys = value.defi ? Object.keys(value.defi) : []

        const keys = {}
        const addresses = []
        for (let i = 0; i < cefiKeys.length; i++) {
            const cefiAccount = value.cefi[cefiKeys[i]]
            keys[cefiAccount.exchange] = {
                apiKey: cefiAccount.apiKey,
                secret: cefiAccount.secretKey
            }
        }
        for (let i = 0; i < defiKeys.length; i++) {
            addresses.push(value.defi[defiKeys[i]].address)
        }

        // this would be the request we send if we werent splitting the requests up individually
        // const request = { keys: keys, addresses: addresses }

        const payloads = [  ]

        for (let i = 0; i < addresses.length; i++) {
            payloads.push({ addresses: [addresses[i]] })
        }

        for (let i = 0; i < Object.keys(keys).length; i++) {
            const inner = {}
            inner[Object.keys(keys)[i]] = keys[Object.keys(keys)[i]] 
            payloads.push({ keys: inner }) 
            payloads.push({ future: true, keys: inner })
        }

        setNumAccounts(payloads.length)
        updateProgress(1)

        return postRequests(payloads, updateProgress, payloads.length)

    } else {
        return null
    }
}

async function postRequests(payloads, updateProgress, numAccounts) {
    let count = numAccounts - payloads.length + 1
    updateProgress(count)
    console.log('starting data load')


    const aggregatedResponses = []


    for (let i = 0; i < payloads.length; i++) {

        console.log('POSTING ' + JSON.stringify(payloads[i]))

        try {
            const response = await axios.post('https://yeho.vercel.app/api/data', payloads[i])
            if (response && response.data) {
                aggregatedResponses.push(response.data)
                count++
                updateProgress(count)
            }
        } catch (e) {
            console.log(e)
            break
        }
    }

    if (aggregatedResponses.length === payloads.length) {
        updateProgress(1)
        return aggregatedResponses
    } else {
        console.log('trying again from where we failed')
        const resultOfTryAgain = await postRequests(payloads.slice(aggregatedResponses.length), updateProgress, numAccounts)

        const final = aggregatedResponses.concat(resultOfTryAgain)

        return final
    }
}